<template>
  <div class="waterfall">
    <div
      v-for="column in columns"
      ref="column"
      :key="column.i"
      :data-index="column.i"
      class="waterfall-column"
    >
      <!-- 别用goodsId做key, 针对泛列表的点后推（参见: wiki：pageId=1470253023），可能会出现点推一样的商品出来 -->
      <!-- 用goods_id做索引页面waterFall报错Duplicate keys detected: -->
      <div
        v-for="i in column.indexes"
        :key="i"
        class="waterfall-item"
      >
        <slot
          :item="items[i]"
          :index="i"
          :nutareIndex="items[i] && items[i].type === 'recommend-for-you' && natureLocal[i]"
        ></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WaterFall',
  props: {
    // 标识该组件是被谁引用了
    useFrom: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => []
    },
    /**
     * {
     *  columns: 2,
     *  itemQuantity: 6 服务端渲染的商品数量
     * }
     */
    ssr: {
      type: Object,
      default: () => ({
        columns: 2,
        itemQuantity: 6
      })
    },
    initWaterFall: {
      type: Boolean,
      default: false
    },
    strictLeftToRight: {
      type: Boolean,
      default: false,
    },
    // 当前点击的商品（需要插坑的商品）在数据数组中（products）的下标
    localIndex: {
      type: [String, Number],
      default: '',
    },
    // 每次动态插坑个数
    dynamicInsertNum: {
      type: [String, Number],
      default: 1,
    },
    deleteItemsFlag: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      columns: [],
      cursor: 0,
      nextIndex: 0,
      natureLocal: {}// 记录插坑之前的位置
    }
  },
  computed: {
    itemQuantity() {
      return this.ssr?.itemQuantity || 0
    },
    count() {
      return this.ssr?.columns || 0
    },
  },
  watch: {
    itemQuantity: {
      handler(val) {
        this.cursor = val
      },
      immediate: true
    },
    items: {
      async handler(newVal, oldVal) {
        if (newVal?.length && newVal?.length !== oldVal?.length) {

          // 删除操作时，只进行删除操作，不进行添加操作
          if (this.deleteItemsFlag && this.cursor >= this.items.length) {
            const deleteStartIndex = newVal.length - 1
            this.columns.forEach(column => {
              const deleteIndex = column.indexes.findIndex(index => index > deleteStartIndex)
              column.indexes.splice(deleteIndex) // 删除大于deleteStartIndex的index
            })
            this.cursor = newVal.length
            this.$emit('update:deleteItemsFlag', false)
            return
          }
          
          // 在切换图文导航 + 网速较慢的极端场景下, 会偶发在上一页 columns 里塞满了 index
          // 但是 initWaterFall 又没有被长时间置为 true, 导致 init 的过程被跳过了, 出现空白商品项的情况
          // 这里增加一下个判断
          if (this.initWaterFall || this.cursor >= this.items.length) {
            this.initInfo()
          }
          if (!this.columns.length) {
            this.initColumns()
          }
          this.addItem(this.nextIndex)
        }
      },
      immediate: true
    },
  },
  mounted() {
    this.$emit('mounted')
  },
  methods: {
    initInfo() {
      this.columns = []
      this.cursor = this.itemQuantity
    },
    initColumns() {
      if (this.count > 0) {
        const columns = this.createColumns(this.count)
        let i = 0
        for (i = 0; i < Math.min(this.itemQuantity, this.items?.length); i++) {
          columns[i % this.count].indexes.push(i)
        }
        // 修正cursor有 极端插坑数据 = itemQuantity 会跳过
        this.cursor = i
        this.columns = columns
      }
    },
    // 需要创建几列
    createColumns(count) {
      const columns = []
      for (let i = 0; i < count; i++) {
        columns.push({ i: i, indexes: [] })
      }
      return columns
    },
    async addItem(nextIndex) {
      // console.log(this.cursor, this.items.length, nextIndex, 'nextIndexnextIndexnextIndex');
      if (typeof window === 'undefined') return
      // 瀑布流塞item完成
      if (this.cursor >= this.items.length) {
        this.$emit('addItemDone')
        return
      }

      if (!this.$refs.column) {
        await this.$nextTick()
      }

      const columnDiv = this.$refs.column
      if (!Array.isArray(columnDiv) || !columnDiv.length) {
        return
      }
      // console.log(nextIndex, 'nextIndexnextIndex', this.cursor);
      let columnIndex
      // 针对点后推动态插坑，改变瀑布流算法
      const nextItem = this.items?.length && this.items[this.cursor]
      const localIndex = typeof nextItem.localIndex === 'number' ? nextItem.localIndex : this.localIndex
      const dynamicInsertNum = nextItem?.type === 'recommend-search-card' ? 1 : this.dynamicInsertNum
      if (['recommend-for-you', 'recommend-search-card'].includes(nextItem?.type) && localIndex > -1) {
        // console.log(this.cursor, 'addItemaddItemaddItemaddItemaddItem', this.items[this.cursor]);
        // 找到当前点击商品， 把推荐商品定位到下方
        //前左侧还是右侧, 拿到下标
        let leftLocalIndexKey = this.columns[0].indexes?.findIndex(item => item === localIndex)
        let rightLocalIndexKey = this.columns[1].indexes?.findIndex(item => item === localIndex)
        const supplementColumnsFun = () => {
          // 插双数，不需要补高逻辑,因为一定是两边一边一个
          if(dynamicInsertNum % 2 === 0) {
            return
          }
          // 两侧高度有差异了，高补低（即将插入短侧则不补）
          if(this.columns[0].indexes.length - this.columns[1].indexes.length >= 2) {
            let val = this.columns[0].indexes.pop()
            this.columns[1].indexes.push(val)
            return
          }
          if(this.columns[1].indexes.length - this.columns[0].indexes.length >= 2) {
            let val = this.columns[1].indexes.pop()
            this.columns[0].indexes.push(val)
          }
        }

        // 大于两个的情况都一样
        if(dynamicInsertNum >= 2) {
          const updateIndexesAndNatureLocal = (natureLocal, isLeft = false) => {
            let leftColumns = [], rightColumns = [], normalColumns = [], tmpIsLeft = isLeft // 需要插入左列的点推商品数组，需要插入左列的点推商品数组，内容体数组（非点推商品记录，会按正常瀑布流逻辑插入左列最后）
            let index = this.cursor
            while(index < this.items.length) {
              let natureLocalLeft, natureLocalRight
              if(isLeft) {
                natureLocalLeft = this.columns[0].indexes[natureLocal + 1]
                natureLocalRight = this.columns[1].indexes[natureLocal]
              } else {
                natureLocalLeft = this.columns[0].indexes[natureLocal + 1]
                natureLocalRight = this.columns[1].indexes[natureLocal + 1]
              }
              // 循环执行一次，左右两列都插入
              if(this.items[index]?.type !== 'recommend-for-you') {
                normalColumns.push(index++)
              } else {
                this.natureLocal[index] = tmpIsLeft ? natureLocalLeft : natureLocalRight
                tmpIsLeft ? leftColumns.push(index++) : rightColumns.push(index++)
                tmpIsLeft = !tmpIsLeft
                natureLocal++
                // this.natureLocal[index] = natureLocalLeft
                // leftColumns.push(index++)
                // // 防止溢出
                // if(index < this.items.length) {
                //   if(this.items[index]?.type !== 'recommend-for-you') {
                //     normalColumns.push(index++)
                //   } else {
                //     this.natureLocal[index] = natureLocalRight
                //     rightColumns.push(index++)
                //   }
                // }
                // natureLocal++
              }

              // this.natureLocal[index] = natureLocalLeft
              // leftColumns.push(index++)
              // if(index < this.items.length) {
              //   this.natureLocal[index] = natureLocalRight
              //   rightColumns.push(index++)
              // }
              // natureLocal++
            }
            this.cursor = this.items.length - 1
            return {
              leftColumns,
              rightColumns,
              normalColumns
            } 
          }

          if(leftLocalIndexKey > -1) {
            // 点左
            let { leftColumns = [], rightColumns = [], normalColumns = [] } = updateIndexesAndNatureLocal(leftLocalIndexKey, true)
            this.columns[0].indexes.splice(leftLocalIndexKey + 1, 0, ...rightColumns)
            this.columns[1].indexes.splice(leftLocalIndexKey, 0, ...leftColumns)
            // 随便插的一列（之后会有补短逻辑）
            normalColumns.length && this.columns[1].indexes.push(...normalColumns)
          } else if(rightLocalIndexKey > -1) {
            // 点右
            let { leftColumns = [], rightColumns = [], normalColumns = [] } = updateIndexesAndNatureLocal(rightLocalIndexKey, false)
            this.columns[0].indexes.splice(rightLocalIndexKey + 1, 0, ...leftColumns)
            this.columns[1].indexes.splice(rightLocalIndexKey + 1, 0, ...rightColumns)
            // 随便插的一列（之后会有补短逻辑）
            normalColumns.length && this.columns[0].indexes.push(...normalColumns)
          }
        }
        
        // 插一个
        if(dynamicInsertNum == 1) {
          console.log('触发点推的瀑布流逻辑执行！！，一次点推触发一次！', this.localIndex)
          if(leftLocalIndexKey > -1) {
            // 点左
            let natureLocalLeft = this.columns[0].indexes[leftLocalIndexKey + 1]
            this.columns[0].indexes.splice(leftLocalIndexKey + 1, 0, this.cursor)
            this.natureLocal[this.cursor] = natureLocalLeft
          }else if(rightLocalIndexKey > -1) {
            // 点右
            let natureLocalRight = this.columns[1].indexes[rightLocalIndexKey + 1]
            this.columns[1].indexes.splice(rightLocalIndexKey + 1, 0, this.cursor)
            this.natureLocal[this.cursor] = natureLocalRight
          }
        }
        supplementColumnsFun()

        let isLeft = leftLocalIndexKey > -1 
        this.nextIndex = isLeft ? 1 : 0
        this.cursor++
        await this.$nextTick()
        this.addItem(this.nextIndex)
        return
      }

      if (this.strictLeftToRight) {
        columnIndex = nextIndex
        this.nextIndex = (nextIndex + 1) % this.count
      } else {
        let noSize = true
        const target = columnDiv.reduce((prev, curr) => {
          // 当前列为空，直接返回当前元素(优化重排)
          if (!prev.lastElementChild || !curr.lastElementChild) {
            noSize = false
            return !prev.lastElementChild ? prev : curr
          }

          const prevHeight = prev.getBoundingClientRect().height
          const currHeight = curr.getBoundingClientRect().height
          if (prevHeight !== 0 || currHeight !== 0) {
            noSize = false
          }
          return prevHeight < currHeight ? prev : curr
        })
        columnIndex = target.dataset.index
        // 若无高度，则各个列轮流添加项nextIndexnextIndexnextIndex
        if (noSize) {
          columnIndex = nextIndex
          this.nextIndex = (nextIndex + 1) % this.count
        }
      }

      const column = this.columns[columnIndex]
      if (this.items[this.cursor]) {
        column.indexes.push(this.cursor)
        this.cursor++
        await this.$nextTick()
        this.addItem(this.nextIndex)
      }
    },
  }
}
</script>

<style lang="less" scoped>
.waterfall {
  .flexbox();
  .space-between();
  align-items: flex-start;
}
.waterfall-column {
  .flexbox();
  flex-direction: column;
}
</style>
